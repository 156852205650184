import MediaPlan from '@/models/MediaPlan'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import { uniqueId } from 'lodash'
import router from '@/Routes'
import Vue from 'vue'
import ExportMediaPlan from '@/pages/Sales/MediaPlan/components/ExportMediaPlan.vue'
import EditTask from '@/pages/Task/components/EditTask.vue'
import WebMessage from '@/models/WebMessage'
import Task from '@/models/Task'
import { sysEnv } from '@/models/interface/Common'

const { user } = getModule(SystemtModule)

export default {
  account_managers: {
    views: [
      {
        id: uniqueId(),
        name: 'My Dashboard',
        cards: [
          {
            id: uniqueId(),
            name: 'My Media Plans',
            type: 'table',
            size: 'lg',
            actions: [
              {
                event: 'export',
                name: 'Export Media Plans',
                icon: 'download',
                action: (media_plans: MediaPlan[]) => {
                  if (media_plans.length === 0) {
                    WebMessage.error('Please select at least 1 Media Plan to download')
                    return
                  }
                  let Component = Vue.extend(ExportMediaPlan)
                  let instance = new Component({
                    propsData: {
                      media_plans,
                      mode: 'single',
                    },
                  })
                  instance.$mount()
                },
              },
              {
                event: 'new',
                name: 'New Media Plan',
                icon: 'plus',
                action: () => {
                  router.push('/app/sales/media_plan/?ref=MyDashboard')
                },
              },
            ],
            settings: {
              model_name: 'media_plan',
              model: MediaPlan,
              fields: [
                'checkbox',
                'number',
                'name',
                'sales_rep',
                'account_manager',
                'formStatus',
                'updated_at',
                'action',
              ],
              filters: {
                account_manager: user?.name,
              },
            },
          },
          {
            id: uniqueId(),
            name: 'Tasks',
            type: 'tasks',
            size: 'sm',
            actions: [
              {
                event: 'new',
                name: 'New Task',
                icon: 'plus',
                action: (_: Task[], dataTable: any) => {
                  let Component = Vue.extend(EditTask)
                  let instance = new Component({
                    propsData: {
                      dataTable,
                    },
                  })
                  instance.$mount()
                },
              },
            ],
            settings: {
              filters: {},
              query: ['is_not:completed'],
            },
          },
          {
            id: uniqueId(),
            name: 'Media Plans by Date',
            type: 'area-chart',
            size: 'xl',
            settings: {
              model_name: 'media_plan',
              model: MediaPlan,
              xaxis: 'date',
              yaxis: 'status',
              metric: 'count',
              period: 'd-30',
              stacked: true,
              filters: {
                account_manager_id: user?.id,
              },
            },
          },
          {
            id: uniqueId(),
            name: 'Media Plan by Sales Rep',
            type: 'bar-chart',
            size: 'lg',
            settings: {
              model_name: 'media_plan',
              model: MediaPlan,
              xaxis: 'sales_rep',
              yaxis: 'status',
              metric: 'count',
              period: 'd-30',
              stacked: true,
              horizontal: false,
              filters: {
                account_manager_id: user?.id,
              },
            },
          },
          {
            id: uniqueId(),
            name: 'Media Plans by Status',
            type: 'pie-chart',
            size: 'sm',
            settings: {
              model_name: 'media_plan',
              model: MediaPlan,
              group: 'status',
              metric: 'count',
              period: 'd-30',
              filters: {
                account_manager_id: user?.id,
              },
            },
          },
        ],
      },
      {
        id: uniqueId(),
        name: 'Overview',
        visible: () =>
          user?.id === sysEnv('account_managers', 'department', 'account_manager_leader') || user?.profile_id === '4ce1a57c-2e2c-11ea-92b6-0a84a8791ed2',
        cards: [
          {
            id: uniqueId(),
            name: 'Media Plans by Date',
            type: 'area-chart',
            size: 'xl',
            settings: {
              model_name: 'media_plan',
              model: MediaPlan,
              xaxis: 'date',
              yaxis: 'status',
              metric: 'count',
              period: 'd-30',
              stacked: true,
            },
          },
          {
            id: uniqueId(),
            name: 'Media Plan by Account Manager',
            type: 'bar-chart',
            size: 'lg',
            settings: {
              model_name: 'media_plan',
              model: MediaPlan,
              xaxis: 'account_manager',
              yaxis: 'status',
              metric: 'count',
              period: 'd-30',
              stacked: true,
              horizontal: false,
            },
          },
          {
            id: uniqueId(),
            name: 'Media Plans by Status',
            type: 'pie-chart',
            size: 'sm',
            settings: {
              model_name: 'media_plan',
              model: MediaPlan,
              group: 'status',
              metric: 'count',
              period: 'd-30',
            },
          },
          {
            id: uniqueId(),
            name: 'Media Plan by Sales Rep',
            type: 'bar-chart',
            size: 'xl',
            settings: {
              model_name: 'media_plan',
              model: MediaPlan,
              xaxis: 'sales_rep',
              yaxis: 'status',
              metric: 'count',
              period: 'd-30',
              stacked: true,
              horizontal: false,
            },
          },
        ],
      },
    ],
  },
}
