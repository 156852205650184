

































































































import { Component, Ref, Vue } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Task from '@/models/Task'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import { clone } from 'lodash'
import { querySettings } from '@/models/metadata/TaskMetadata'
import EditTask from '@/pages/Task/components/EditTask.vue'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
    SelectPicker,
  },
})
export default class TaskHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public task: Task = new Task()

  public records: number = 0

  public loading: boolean = false

  public query: string[] = ['is:mine', 'is_not:completed']

  public selected: string[] = []

  public fieldFilters: any = {}

  public table_data: Task[] = []

  public fields: Array<any> = []

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public get query_settings() {
    return querySettings
  }

  public rows(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    return Task.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.loading = false
      this.table_data = result.data
      return result.data
    })
  }

  public createTask() {
    let Component = Vue.extend(EditTask)
    let instance = new Component({
      propsData: {
        dataTable: this,
      },
    })
    instance.$mount()
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.refresh()
  }

  public mounted() {
    this.fields = clone(Task.tableFields)
  }
}
