











































import {
  Component, Prop, Vue, Ref, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import settings from './settings'
import Card from './components/Card.vue'

@Component({
  components: {
    Card,
    IconAction,
  },
})
export default class Dashboard extends ViewModel {
  private activeViewId: string = ''

  public get settings() {
    // @ts-ignore
    return settings[this.user.department]
  }

  public get views() {
    return this.settings.views.filter((view: any) => view.visible === undefined || view.visible())
  }

  public get activeView() {
    return this.views.find((view: any) => view.id === this.activeViewId)
  }

  public created() {
    this.activeViewId = this.views[0].id
  }

  private newView() {
    // console.log('New View')
  }
}
