










































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Task from '@/models/Task'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import EditTask from '@/pages/Task/components/EditTask.vue'
import WebMessage from '@/models/WebMessage'
import HtmlContent from '@/components/HtmlContent/HtmlContent.vue'

@Component({
  components: {
    SelectPicker,
    EditTask,
    HtmlContent,
  },
})
export default class TaskCard extends ViewModel {
  @Prop({ default: false })
  public busy!: boolean

  @Prop({ required: true })
  public settings!: any

  @Prop({ default: () => [] })
  public actions!: any

  public records: number = 0

  public rows: Task[] = []

  public filter_options = [
    { text: 'Owned by Me', value: 'is:owned' },
    { text: 'My Tasks', value: 'is:assigned' },
  ]

  public selected_filter: string = 'is:assigned'

  public pagination_options: any = [
    { name: '10', value: '10' },
    { name: '25', value: '25' },
    { name: '50', value: '50' },
    { name: '100', value: '100' },
  ]

  public sort_by: string = 'system_priority'

  public page_size: number = 5

  public page: number = 1

  public sort_desc: boolean = true

  public selected: string[] = []

  public query: string[] = []

  private data: Task[] = []

  private show: boolean = false

  private target: string | null = null

  public get table() {
    return this
  }

  private get localBusy() {
    return this.busy
  }

  private set localBusy(value: boolean) {
    this.$emit('update:busy', value)
  }

  @Watch('page_size')
  public onPageSizeChange() {
    this.refresh()
  }

  @Watch('page')
  public onPageChange() {
    this.refresh()
  }

  @Watch('selected_filter')
  public onFilterChange() {
    this.refresh()
  }

  public refresh() {
    this.localBusy = true
    Task.paginate({
      page_size: this.page_size,
      page: this.page,
      order_by: this.sort_by,
      order: this.sort_desc ? 'desc' : 'asc',
      query: [...this.query, this.selected_filter],
    }).then(result => {
      this.records = result.records
      this.localBusy = false
      this.rows = result.data
      this.data = result.data
      // return result.data
    })
  }

  public trigger(event: string) {
    let action = this.actions.find((a: any) => a.event === event)
    if (action && action.action !== undefined) {
      action.action(
        this.data.filter((item: any) => this.selected.includes(item.id)),
        this,
      )
    }
  }

  public viewTask(task: Task) {
    this.target = task.id
    this.show = true
  }

  public completeTask(task: Task) {
    task.status = 'completed'
    this.localBusy = true
    task.save().then(() => {
      this.refresh()
    })
  }

  public deleteTask(task: Task) {
    WebMessage.confirm(
      `Are you sure that you want to delete the Task "<strong>${task.name}</strong>"? You won't be able to restore it!`,
      'Delete Task',
    ).then((value: boolean) => {
      if (value) {
        this.localBusy = true
        task.delete().then(() => {
          this.refresh()
        })
      }
    })
  }

  public startTask(task: Task) {
    task.status = 'in_progress'
    this.localBusy = true
    task.save().then(() => {
      this.refresh()
    })
  }

  public holdTask(task: Task) {
    task.status = 'hold'
    this.localBusy = true
    task.save().then(() => {
      this.refresh()
    })
  }

  public created() {
    this.query = this.settings.query
    this.refresh()
  }
}
