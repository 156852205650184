





















































import { Component, Prop, Ref } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import TableCard from './Cards/TableCard.vue'
import PieChartCard from './Cards/PieChartCard.vue'
import AreaChartCard from './Cards/AreaChartCard.vue'
import TaskCard from './Cards/TaskCard.vue'

@Component({
  components: {
    Widget,
    TableCard,
    PieChartCard,
    AreaChartCard,
    TaskCard,
    IconAction,
  },
})
export default class Card extends ViewModel {
  @Ref() private readonly card!: any

  @Prop({ default: '' })
  public title!: string

  @Prop({ required: true })
  public type!: string

  @Prop({ default: 'large' })
  public size!: string

  @Prop({ required: true })
  public settings!: any

  @Prop({ default: () => [] })
  public actions!: any

  private busy: boolean = false

  private get colSize() {
    switch (this.size) {
      case 'xs':
        return {
          lg: 2,
          md: 4,
          sm: 12,
          xs: 12,
        }
      case 'sm':
        return {
          lg: 4,
          md: 6,
          sm: 12,
          xs: 12,
        }
      case 'md':
        return {
          lg: 6,
          md: 6,
          sm: 12,
          xs: 12,
        }
      case 'lg':
        return {
          lg: 8,
          md: 8,
          sm: 12,
          xs: 12,
        }
      default:
        return {
          lg: 12,
          md: 12,
          sm: 12,
          xs: 12,
        }
    }
  }

  public refresh() {
    this.card.refresh()
  }

  private trigger(event: string) {
    this.card.trigger(event)
  }
}
